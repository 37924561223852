import '@fontsource-variable/red-hat-mono';
import '@fontsource-variable/red-hat-text';
import { createRoot } from 'react-dom/client';

import 'src/config/env';
import { initSentry } from '@/config/sentry';
import App from '@/main/App';
import reportWebVitals from '@/utils/reportWebVitals';

initSentry();

const container = document.getElementById('root')!;
const root = createRoot(container);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
